import * as React from 'react';
import { Link } from 'gatsby';
import { Box, Container, Stack, Flex, SimpleGrid } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../components/seo';
import Layout from '../components/layout';
import CustomButton from '../components/custom-button';
import '../styles/applications.scss';

const Applications = () => (
  <Layout>
    <SEO
      title="Fertilizer Benefits | Crystal Green | Ostara"
      description="Learn how to enhance yield and solve key nutrient challenges with the help of our patented Root-Activated Crystal Green fertilizers. "
      image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
      keywords="Crystal Green Fertilizer Benefits"
    />
    <main>
      <Box w="100%" p={4} className="product-hero-container">
        <Container maxW={1400}>
          <Box
            display="flex"
            flexDirection={['column', 'column', 'column', 'row']}
            justifyContent="space-between"
            paddingTop={[0, 0, 10, 50]}
          >
            <Box w={['100%', '100%', '100%', '46.5%']}>
              <Stack maxW="50rem" spacing={6}>
                <h1>Benefits</h1>
                <p>
                  Learn how to enhance yield and solve key challenges with the
                  help of Crystal Green fertilizers.
                </p>
              </Stack>
            </Box>
            <Stack
              spacing={[4, 6, 4, 6, 8]}
              w={['100%', '100%', '100%', '46.5%']}
            >
              <Box className="benefits-hero-img-container">
                <StaticImage
                  className="hero-img"
                  placeholder="transparent"
                  src="../images/applications.png"
                  alt="Ostara hero img"
                />
              </Box>
            </Stack>
          </Box>
        </Container>
      </Box>

      <Box w="100%" p={4} className="applications-body-container">
        <Container
          className="applications-body-content-1--container"
          maxW="container.md"
        >
          <div className="applications-body-content-1--heading">
            <h3>
              Discover the Data-Backed Benefits of Crystal Green Fertilizers
            </h3>
            <p>
              With ongoing research with accredited institutions from around the
              world, Crystal Green fertilizers are proven to enhance agriculture
              growth and sustainability.
            </p>
          </div>
        </Container>
        <Container className="key-top-box" maxW={1400} />
        <Container className="applications-body-2--container" maxW={1400}>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={24}>
            <Flex
              direction="column"
              className="applications-body-content-2--card"
            >
              <StaticImage
                placeholder="transparent"
                src="../images/improve-yield-thumbnail-min.png"
                alt="Ostara icons"
              />
              <h3>Improve Yield</h3>
              <p>
                Increase yield and quality by putting your crops in control of
                nutrient uptake with Crystal Green.
              </p>
              <CustomButton maxW={180} to="/applications/improve-yield">
                LEARN HOW
              </CustomButton>
            </Flex>
            <Flex
              direction="column"
              className="applications-body-content-2--card"
            >
              <StaticImage
                placeholder="transparent"
                src="../images/soil-fixation-min.png"
                alt="Ostara icons"
              />
              <h3>Prevent Soil Fixation</h3>
              <p>
                Crystal Green’s sparingly water solubility prevents phosphate
                from being tied-up in the soil and remains available to plants.
              </p>
              <CustomButton maxW={180} to="/applications/soil-tie-up">
                LEARN HOW
              </CustomButton>
            </Flex>
            <Flex
              direction="column"
              className="applications-body-content-2--card"
            >
              <StaticImage
                placeholder="transparent"
                src="../images/nutrient-runoff-thumb-min.png"
                alt="Ostara icons"
              />
              <h3>Reduce Nutrient Runoff</h3>
              <p>
                Keep nutrients available for plant uptake and reduces runoff and
                leaching into local waterways.
              </p>
              <CustomButton maxW={180} to="/applications/nutrient-runoff">
                LEARN HOW
              </CustomButton>
            </Flex>

            <Flex
              direction="column"
              className="applications-body-content-2--card"
            >
              <StaticImage
                placeholder="transparent"
                src="../images/enhance-turf-min.png"
                alt="Ostara icons"
              />
              <h3>Enhance Turf & Ornamental Applications</h3>
              <p>
                Grow turf more sustainably, with faster recovery time, improved
                stand and color, and reduced leaching and runoff.
              </p>
              <CustomButton maxW={180} to="/applications/turf-ornaments">
                LEARN HOW
              </CustomButton>
            </Flex>
          </SimpleGrid>
        </Container>
      </Box>
      <Container className="body-cta-container" maxW={1400}>
        <Box>
          <Link to="/contact">Let’s Talk About Your Nutrient Challenges</Link>
        </Box>
      </Container>
    </main>
  </Layout>
);

export default Applications;
